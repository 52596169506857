.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.report-container {
  height: 75vh;
  margin: 8px auto;
  width: 65%;
}

.plot-canvas {
  height: 75vh;
  margin: 8px auto;
  width: 50%;
}

.plot-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

body {
  font-family: 'Times New Roman', Times, serif;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background: #3476ae 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  color: #ffffff;
  font: 700 22px/27px;
  padding: 13px 13px 13px 36px;
  text-align: left;
}

.display-message {
  align-items: center;
  display: flex;
  font: 400 18px/27px;
  height: 30px;
  justify-content: center;
  margin-top: 8px;
  text-align: center;
}

.position {
  margin-top: 40vh;
}

.embed-report {
  margin-top: 18px;
  text-align: center;
  margin-right: 0;
}

.controls {
  margin-top: 20px;
  text-align: center;
  flex: 1;
}

.footer {
  align-items: center;
  background: #3476ae 0 0 no-repeat padding-box;
  color: #ffffff;
  display: flex;
  font: 400 16px/21px;
  height: 42px;
  justify-content: center;
  width: 100%;
}

.footer * {
  padding: 0 3px;
}

.footer-icon {
  border-radius: 50%;
  height: 22px;
  vertical-align: middle;
}

.footer a {
  color: #3a3a3a;
  text-decoration: underline;
}

button {
  background: #337ab7;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  height: 35px;
  margin-right: 15px;
  width: 160px;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none;
}

input[type="text"],
input[type="password"],
input[type="month"],
select {
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}



input[type="submit"] {
  background: #337ab7;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  height: 35px;
  width: 150px;
  margin-top: 10px;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.success {
  color: green;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

.input-container {
  gap: 0px;
  margin: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 400px;
}


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}


.login-form {
  padding: 30px;
  border: 1.5px solid #7070703d;
  width: 20%;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.login-input-container {
  gap: 10px;
  height: 60px;
  margin: 5px;
}

/* =========================================================================================================================================================== */

.tablink {
  background-color: #2a6598;
  float: right;
  cursor: pointer;
  font-size: 17px;
  width: 10%;
  margin-top: 5px;
  height: 80%;
  opacity: 0.8;
}

.tablink:hover {
  background-color: #2a6598;
  opacity: 1;
}

/* =========================================================================================================================================================== */

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.6);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  border-radius: 2%;
  position: relative;
  background-color: #3476ae;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 480px;
  height: 420px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  color: white;
}

.modal-body {
  padding: 0px;
  background: white 0 0 no-repeat padding-box;
  height: 80%;
}

.modal-footer {
  padding: 2px 16px;
  background: #3476ae 0 0 no-repeat padding-box;
  color: white;
}

.modal-close{
    background-color: transparent;
    float: right;
    cursor: pointer;
    width: 30px;
    margin-right: -10px;
    opacity: 0.8;
}


/* =========================================================================================================================================================== */

/* Style the tab */
.tab {
  overflow: hidden;
  border: none;
  background-color: #6094c2;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: right;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.1s;
  font-size: 17px;
  border-radius: 0px;
  margin: 0px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #3476ae;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #3172ac;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 12px 12px;
  border-top: none;
  height: 100%;
}

.admin-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}